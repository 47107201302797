import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {ButtonComponent, ButtonType} from '@shared/components/ui/buttons/button/button.component';
import {ModalTitleComponent} from '@shared/components/modal/modal-title/modal-title.component';
import {ModalWrapperComponent} from '@shared/components/modal/modal-wrapper/modal-wrapper.component';
import {ModalService} from '@shared/components/modal/modal.service';
import {
  ProductToDeleteComponent
} from '@pages/basket/products-list/delete-product-modal/product-to-delete/product-to-delete.component';
import {BasketService} from '@shared/services/api/basket.service';
import {catchError, EMPTY, take} from 'rxjs';
import {BasketEntity} from '@shared/models/entities/Basket.type';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-delete-product-modal',
  imports: [
    ButtonComponent,
    ModalTitleComponent,
    ModalWrapperComponent,
    ProductToDeleteComponent,
    TranslatePipe
  ],
  templateUrl: './delete-product-modal.component.html',
  styleUrl: './delete-product-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteProductModalComponent {
  private modalService = inject(ModalService);
  private basketService = inject(BasketService);
  basketEntity = input.required<BasketEntity>();
  basketItem = computed(() => {
    return this.basketService.basket()?.find(basketItem => basketItem.entity.id === this.basketEntity().id);
  });

  delete() {
    const basketItem = this.basketItem();
    if (!basketItem) {
      console.error('BasketItem not found.');
      return;
    }

    if (basketItem.entity.isPostcard && basketItem.postCardComment) {
      this.basketService.addTextToFirstPostcardInBasket(basketItem.postCardComment, basketItem.id)
        .pipe(
          take(1),
          catchError((err) => {
            console.log(err);
            return EMPTY;
          }),
        ).subscribe()
    }

    this.basketService.deleteItem(basketItem.id)
      .pipe(
        take(1),
        catchError((err) => {
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.modalService.hideTooltip();
      })
  }

  close() {
    this.modalService.hideTooltip();
  }


  protected readonly ButtonType = ButtonType;
}
