import {computed, inject, Injectable, signal} from '@angular/core';
import {RoutesConstants} from '@core/constants/routes.constants';
import {BaseService} from '@core/services/base.service';
import {HttpParams} from '@angular/common/http';
import {catchError, EMPTY, filter, finalize, fromEvent, of, Subscription, switchMap, take, tap} from 'rxjs';
import {AuthHelperService} from '../auth/auth-helper.service';
import {AuthService} from '@shared/services/auth/auth.service';
import {Basket, BasketEntity, BasketItem, BasketItemType} from '@shared/models/entities/Basket.type';
import {ModalService, ModalType} from '@shared/components/modal/modal.service';
import {
  DeleteProductModalComponent
} from '@pages/basket/products-list/delete-product-modal/delete-product-modal.component';
import {GoodsListItem} from '@shared/models/entities/Goods.type';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';
import {DOCUMENT} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class BasketService extends BaseService {
  private authHelperService = inject(AuthHelperService);
  private authService = inject(AuthService);
  private modalService = inject(ModalService);
  private pd = inject(PlatformDetectorService);
  private document = inject(DOCUMENT);

  private focusSubscription?: Subscription;

  private _basket = signal<Basket | null>(null);
  basket = this._basket.asReadonly();
  orderItems = computed(() => this.basket()
    ?.filter(basketItem => basketItem.entity.price > 0)
  )
  quantity = computed(() => {
    const basketItems = this.basket();
    if (!basketItems) {
      return 0;
    }

    return basketItems
      .filter(basketItem => basketItem.entity.price > 0)
      .reduce((acc, goods) => acc + (goods.quantity || 0), 0);
  });
  total = computed(() => this.basket()?.reduce((a, b) => a + b.priceAmount, 0) || 0);
  loading = signal(true);
  postcardsInBasket = computed(() => (this.basket() || []).filter(bi => bi.entity.isPostcard));


  constructor() {
    super(RoutesConstants.BASKET.path);

    this.reinitOnBrowserTabFocusHandler();
  }

  init() {
    return this.getBasketObservable().pipe(
      take(1)
    ).subscribe();
  }

  reinit() {
    this.getBasketObservable().pipe(
      take(1)
    ).subscribe();
  }


  getBasketItemByEntityId(entityId: number): BasketItem | null {
    return this.basket()?.find(basketItem => basketItem.entity.id === entityId) || null;
  }

  setToCard(count: number, entity: GoodsListItem) {
    console.log('setToCard', entity);
    const {id} = entity;
    const type = (entity as unknown as any).boxType ? BasketItemType.BOX : BasketItemType.GOODS;
    const basketItem = this.getBasketItemByEntityId(id);
    const isAdding = !basketItem && count > 0;
    const isRemoving = basketItem && count <= 0;
    const isChangingQuality = basketItem && count >= 1;

    if (isAdding) {
      this.addItemToBasket(count, id, type);
    } else if (isRemoving) {
      this.showDeleteItemFromBasketModal(entity);
    } else if (isChangingQuality) {
      this.changeItemQuantity(basketItem.id, count);
    } else {
      console.error('unknown command in setToCard');
    }

  }

  deleteItem(id: number) {
    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const params = new HttpParams({fromObject: {...tokenDto}});

        return this.http.delete<Basket>(`${this.REST_PATH_V2}/item/${id}`, {params});
      }),
      catchError(err => this.handleError(err)),
      tap(basket => {
        this._basket.set(basket)
      })
    )
  }

  addTextToFirstPostcardInBasket(postCardComment: string, exceptBasketItemId?: number) {
    const firstPostcardInBasket = this.postcardsInBasket().filter(item => item.id !== exceptBasketItemId)[0];
    if (!firstPostcardInBasket) {
      return EMPTY;
    }
    return this.addPostcardComment({id: firstPostcardInBasket.id, postCardComment: postCardComment})
  }

  private addPostcardComment(dto: AddPostcardCommentDto) {
    const anonymousTokenDto = this.authService.getAnonymousTokenDtoIfNotLogged();
    const {id, ...rest} = dto;
    return this.http.post(`${this.REST_PATH_V2}/add-postcard-comment/${id}`, {...anonymousTokenDto, ...rest})
  }

  private getBasketObservable() {
    return of(this.authService.isLoggedInOrAnonymous()).pipe(
      tap((logged) => {
        if (!logged) {
          this._basket.set(null);
        }
      }),
      filter((logged) => logged && this.pd.isBrowser()),
      switchMap(() => this.getBasket()),
    )
  }

  private getBasket() {
    this.loading.set(true);
    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const params = new HttpParams({fromObject: {...tokenDto}});
        return this.http.get<Basket>(`${this.REST_PATH_V2}`, {params});
      }),
      catchError(this.handleError),
      tap(basket => {
        this._basket.set(basket)
      }),
      finalize(() => {
        this.loading.set(false);
      })
    );
  }

  private addItems(itemsDto: AddItemsToBasketDto) {
    this.loading.set(true);

    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const body = {...itemsDto, ...tokenDto};
        return this.http.post<Basket>(`${this.REST_PATH_V2}/items`, {...body});
      }),
      tap(basket => {
        this._basket.set(basket)
      }),
      catchError(error => {
        const badTokenProvided = error.status === 400 && error?.error?.message === 'Wrong order user provided.'
        if (badTokenProvided) {
          this.authService.anonymousLogout();
        }
        return this.handleError(error);
      }),
      finalize(() => {
        this.loading.set(false);
      })
    )
  }

  private reinitOnBrowserTabFocusHandler() {
    if (this.pd.isBrowser()) {
      this.focusSubscription?.unsubscribe();

      this.focusSubscription = fromEvent(this.document, 'visibilitychange')
        .pipe(
          filter(() => this.document.visibilityState === 'visible')
        )
        .subscribe(() => {
          this.reinit();
        });
    }
  }

  private deleteBasket() {
    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const params = new HttpParams({fromObject: {...tokenDto}});

        return this.http.delete(`${this.REST_PATH_V2}`, {params});
      }),
      catchError(this.handleError),
      tap(() => {
        this._basket.set(null)
      }),
    )
  }

  private changeQuantity(id: number, query: IncreaseQuantityQuery) {
    this.loading.set(true);

    return this.authHelperService.getAuthToken().pipe(
      switchMap(tokenDto => {
        const body = {...tokenDto, ...query};

        return this.http.put<Basket>(`${this.REST_PATH_V2}/item/${id}`, {...body});
      }),
      catchError(this.handleError),
      tap(basket => {
        this._basket.set(basket)
      }),
      finalize(() => {
        this.loading.set(false);
      })
    )
  }

  private addItemToBasket(amount: number, productId: number, type: BasketItemType) {
    this.addItems({
      items: [{
        type: type,
        refId: productId,
        quantity: amount,
      }]
    }).pipe(take(1)).subscribe();
  }

  private showDeleteItemFromBasketModal(entity: BasketEntity) {
    this.modalService.show({
      component: DeleteProductModalComponent,
      inputs: {basketEntity: entity},
    }, ModalType.MODAL_CENTER);
  }

  private changeItemQuantity(itemId: number, quantity: number) {
    this.changeQuantity(itemId, {quantity}).pipe(take(1)).subscribe();
  }

  private handleError = (error: any) => {
    // Update basket with prev elements. Need to create new objects to trigger changes
    this._basket.update(prev => prev ? [...prev.map(p => ({...p}))] : null);
    return EMPTY;
  }
}

export type AddItemsToBasketDto = {
  items: AddItemToBasket[]
}

export interface AddItemToBasket {
  type: BasketItemType,
  refId: number;
  quantity: number;
}

export interface IncreaseQuantityQuery {
  quantity: number;
}

export interface AddPostcardCommentDto {
  id: number;
  postCardComment: string;
}
