@let item = basketItem();

<app-modal-wrapper>
  <aside class="modal">
    <app-modal-title>{{ 'basket.confirmDeleteItem' | translate }}</app-modal-title>
    @if (item) {
      <app-product-to-delete [basketEntity]="item"></app-product-to-delete>
    }
    <div class="buttons-group">
      <app-button (click)="delete()" fullWidth [buttonType]="ButtonType.PRIMARY"><span>{{'common.values.delete' | translate}}</span></app-button>
      <app-button (click)="close()" fullWidth [buttonType]="ButtonType.SECONDARY"><span>{{'common.values.cancel' | translate}}</span></app-button>
    </div>
  </aside>
</app-modal-wrapper>

