import {ImageObject} from '@shared/models/entities/MainPageBanner.type';
import {Category} from '@shared/models/entities/Category.type';

export type Basket = BasketItem[];

export interface BasketItem {
  id: number;
  type: BasketItemType;
  quantity: number;
  entity: BasketEntity;
  priceAmount: number;
  postCardComment: string;
  buyPrice: number;
}

export enum BasketItemType {
  GOODS = 'GOODS',
  BOX = 'BOX',
}

export interface BasketEntity {
  id: number;
  price: number;
  discountPrice: number | null;
  image: ImageObject;
  name: string;
  slug: string;
  category?: Category;
  boxType?: BoxType; // Optional for items without boxType
  quantity?: number;
  isPostcard?: boolean;
}

export enum BoxType {
  DEFAULT = 'DEFAULT',
  GIFT = 'GIFT',
  GOODS = 'GOODS',
}
