import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CreateRoutePipe} from '@shared/pipes/create-route.pipe';
import {ImageComponent} from '@shared/components/common/image/image.component';
import {ImageResizerPipe} from '@shared/pipes/image-resizer.pipe';
import {RoutesConstants} from '@core/constants/routes.constants';
import {BasketItem} from '@shared/models/entities/Basket.type';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-product-to-delete',
  imports: [
    CreateRoutePipe,
    ImageComponent,
    ImageResizerPipe,
    TranslatePipe
  ],
  templateUrl: './product-to-delete.component.html',
  styleUrl: './product-to-delete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductToDeleteComponent {
  basketEntity = input.required<BasketItem>();
  protected readonly RoutesConstants = RoutesConstants;
}
