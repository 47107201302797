@let entity = basketEntity();
@let item = basketEntity().entity;

<article class="card">
  <div class="wrapper">
    @if (item.slug) {
      <a class="image" [href]="[RoutesConstants.PRODUCT.path, item.slug] | createRoute" [attr.aria-label]="item.name">
        <app-image [src]="item.image.src | imageResizer : {w: 240}" [alt]="item.image.altText"></app-image>
      </a>
    } @else {
      <span class="image">
        <app-image [src]="item.image.src | imageResizer : {w: 240}" [alt]="item.image.altText"></app-image>
      </span>
    }

    <div class="info">
      @if (item.slug) {
        <a class="name" [href]="[RoutesConstants.PRODUCT.path, item.slug] | createRoute" [attr.aria-label]="item.name">
          {{ item.name }}
        </a>
      } @else {
        <span>
          {{ item.name }}
        </span>
      }

      <span class="label"
      >{{ 'common.values.amount' | translate }}: {{ entity.quantity }}</span>
    </div>
  </div>

  <div class="amount">
    <div class="price">
      <span class="total">{{ entity.priceAmount || '0' }} {{ 'common.values.currency' | translate }}</span>
    </div>
  </div>

</article>
